import { PostgrestClient } from "@supabase/postgrest-js";
import { HistoryTimelineEntry } from "./HistoryTimelineEntry";

export type NewEntry = Omit<HistoryTimelineEntry, "id">;

const postgrest = new PostgrestClient(
  "https://rolflerntgeschichte-api.jakl.dev",
  { throwOnError: true }
);

function transformEntry<T extends HistoryTimelineEntry | NewEntry>(
  entry: T
): T {
  return {
    ...entry,
    end: entry.end?.trim() ? entry.end.trim() : undefined, // skip end if its empty
  };
}

export async function loadData(): Promise<HistoryTimelineEntry[]> {
  const { data } = await postgrest
    .from<HistoryTimelineEntry>("data")
    .select()
    .order("start", { ascending: true })
    .order("id", { ascending: true });

  return data || [];
}

export async function addData(entry: NewEntry, token?: string): Promise<void> {
  if (!token) {
    throw new Error("No token");
  }

  await postgrest
    .auth(token)
    .from<HistoryTimelineEntry>("data")
    .insert(transformEntry(entry));

  return;
}

export async function updateData(
  entry: HistoryTimelineEntry,
  token?: string
): Promise<void> {
  if (!token) {
    throw new Error("No token");
  }

  await postgrest
    .auth(token)
    .from<HistoryTimelineEntry>("data")
    .update(transformEntry(entry))
    .match({ id: entry.id });

  return;
}

export async function deleteData(id: number, token?: string): Promise<void> {
  if (!token) {
    throw new Error("No token");
  }

  await postgrest
    .auth(token)
    .from<HistoryTimelineEntry>("data")
    .delete()
    .match({ id });

  return;
}
