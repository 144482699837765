import React, { ReactElement } from "react";

import "vis-timeline/styles/vis-timeline-graph2d.css";
import "./HistoryTimeline.css";
import _ from "lodash";
import { typeToEmoji } from "./utils";
import VisTimeline from "./VisTimeline";
import { HistoryTimelineEntry } from "./HistoryTimelineEntry";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export interface HistoryTimelineProps {
  entries: HistoryTimelineEntry[];
}

export function HistoryTimeline({
  entries,
}: HistoryTimelineProps): ReactElement {
  const stats = _.countBy(entries, "type");
  const [filter, setFilter] = React.useState(_.map(stats, (value, key) => key));

  const toggle = (type: string) => {
    if (show(type)) {
      setFilter(filter.filter((t) => t !== type));
    } else {
      setFilter([...filter, type]);
    }
  };

  const show = (type: string) => {
    return filter.includes(type);
  };

  const filteredEntries = entries.filter((entry) =>
    filter.includes(entry.type)
  );

  return (
    <Stack height="100vh" maxHeight="fill-available">
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h4">
            Unsere Geschichte <Link to="/admin">🧑‍🎓</Link>
          </Typography>
        </Grid>
        <Grid item margin={1}>
          <Stack direction="row" spacing={1}>
            {_.map(stats, (count, type) => {
              return (
                <Stack
                  key={type}
                  className={`filter-button ${
                    show(type) ? "active" : "inactive"
                  }`}
                  onClick={() => toggle(type)}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                >
                  <Box>{typeToEmoji(type)}</Box>
                  <Box>{count}</Box>
                </Stack>
              );
            })}
          </Stack>
        </Grid>
      </Grid>
      <VisTimeline
        entries={filteredEntries}
        startYearsAgo={50}
        endYearsInFuture={3}
        minZoomDays={5}
        width="100%"
        height="100%"
      />
    </Stack>
  );
}
