import React, { ReactElement } from "react";
import { useHistoryTimelineData } from "./UseHistoryTimelineData";
import { HistoryTimelineEntry } from "./HistoryTimelineEntry";
import { Button, Stack, Typography } from "@mui/material";
import _ from "lodash";
import NewEntryForm from "./NewEntryForm";
import EditEntriesGrid from "./EditEntriesGrid";
import { Link } from "react-router-dom";
import { deleteFromStorage } from "@rehooks/local-storage";

export default function Admin(): ReactElement {
  const { data, deleteEntry, addEntry, updateEntry } = useHistoryTimelineData();

  const onUpdate = (
    newData: HistoryTimelineEntry,
    oldData: HistoryTimelineEntry
  ) => {
    if (_.isEqual(newData, oldData)) {
      return newData;
    }
    updateEntry(newData);
    return newData;
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <Stack height="100vh" maxHeight="fill-available" spacing={3}>
      <Stack direction="row" justifyContent="space-between" maxWidth="1200px">
        <Link to={"/"}>
          <Typography variant="body1">Back to the Timeline</Typography>
        </Link>
        <Button variant="outlined" onClick={() => deleteFromStorage("token")}>
          Logout
        </Button>
      </Stack>
      <NewEntryForm onSubmit={addEntry} />
      <EditEntriesGrid data={data} onUpdate={onUpdate} onDelete={deleteEntry} />
    </Stack>
  );
}
