// This also sets the order how the values are shown in the dropdown
export const EVENT_TYPES = [
  "EVENT",
  "LIFESPAN",
  "BIRTH",
  "WAR",
  "NUCLEAR",
  "WEDDING",
  "EPOCH",
  "LITERATURE",
];

const MAPPING = new Map<string, string>([
  ["WAR", "⚔️"],
  ["BIRTH", "🐣"],
  ["EVENT", "🗞"],
  ["NUCLEAR", "💥"],
  ["LIFESPAN", "🤘🏻"],
  ["WEDDING", "💍"],
  ["EPOCH", "⏳"],
  ["LITERATURE", "📚"],
]);

export function typeToEmoji(type: string) {
  return MAPPING.get(type) || "🤷‍♂️";
}
