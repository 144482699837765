import { useEffect, useState } from "react";
import { HistoryTimelineEntry } from "./HistoryTimelineEntry";
import {
  addData,
  deleteData,
  loadData,
  NewEntry,
  updateData,
} from "./postgrest";
import { useLocalStorage } from "@rehooks/local-storage";

export interface TimelineData {
  data?: HistoryTimelineEntry[];
  deleteEntry: (id: number) => void;
  addEntry: (entry: NewEntry) => void;
  updateEntry: (entry: HistoryTimelineEntry) => void;
}

export function useHistoryTimelineData(): TimelineData {
  const [dirty, setDirty] = useState(true);
  const [data, setData] = useState<HistoryTimelineEntry[]>();
  const [token] = useLocalStorage("token", "");

  useEffect(() => {
    if (dirty) {
      loadData()
        .then((data) => {
          setData(data);
          setDirty(false);
        })
        .catch((error) => console.error(error));
    }
  }, [dirty, setDirty]);

  const addEntry = (entry: NewEntry) => {
    addData(entry, token)
      .then(() => setDirty(true))
      .catch((error) => console.error(error));
  };

  const updateEntry = (entry: HistoryTimelineEntry) => {
    updateData(entry, token)
      .then(() => setDirty(true))
      .catch((error) => console.error(error));
  };

  const deleteEntry = (id: number) => {
    deleteData(id, token)
      .then(() => setDirty(true))
      .catch((error) => console.error(error));
  };

  return {
    data,
    deleteEntry,
    addEntry,
    updateEntry,
  };
}
