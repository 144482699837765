import React, { ReactElement, useState } from "react";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { writeStorage } from "@rehooks/local-storage";
import { Link } from "react-router-dom";

export default function Login(): ReactElement {
  const [token, setToken] = useState("");

  const storeToken = (): void => {
    writeStorage("token", token);
  };

  return (
    <Stack width="50%" margin="auto" spacing={3}>
      <Typography variant="h4">Provide your JWT</Typography>
      <TextField
        id="outlined-basic"
        label="Enter your JWT"
        variant="outlined"
        fullWidth
        value={token}
        onChange={(e) => setToken(e.target.value)}
      />
      <Button variant="contained" color="primary" onClick={storeToken}>
        OK
      </Button>
      <Link to={"/"}>
        <Typography variant="body1">Back to the Timeline</Typography>
      </Link>
    </Stack>
  );
}
