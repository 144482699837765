import React, { ReactElement, useEffect } from "react";
import { DataSet, Timeline } from "vis-timeline/standalone";
import { typeToEmoji } from "./utils";
import "./VisTimeline.css";
import { HistoryTimelineEntry } from "./HistoryTimelineEntry";
import { Box } from "@mui/material";

export interface VisTimelineProps {
  entries: HistoryTimelineEntry[];
  startYearsAgo: number;
  endYearsInFuture: number;
  minZoomDays: number;
  width: string;
  height: string;
}

const groups = [
  { id: "point", content: "" },
  { id: "range", content: "" },
];

function compileTooltip(entry: HistoryTimelineEntry) {
  let tooltip = `<b>${entry.title}</b><br>${entry.start}`;
  if (entry.end) {
    tooltip += ` &mdash; ${entry.end}`;
  }
  return tooltip;
}

function convertEntries(entries: HistoryTimelineEntry[]) {
  return entries.map((entry, index) => {
    return {
      id: index,
      content: entry.type
        ? `${typeToEmoji(entry.type)} ${entry.title}`
        : entry.title,
      title: compileTooltip(entry),
      start: entry.start,
      end: entry.end === "ongoing" ? new Date() : entry.end,
      type: entry.end ? "range" : "point",
      group: entry.end ? "range" : "point",
      className: entry.type,
    };
  });
}

export default function VisTimeline({
  entries,
  startYearsAgo,
  endYearsInFuture,
  minZoomDays,
  width,
  height,
}: VisTimelineProps): ReactElement {
  const [timeline, setTimeline] = React.useState<Timeline>();
  const ref = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (timeline) {
      const items = convertEntries(entries);
      timeline.setData({ items });
    }
  }, [timeline, entries]);

  useEffect(() => {
    if (ref?.current) {
      const now = new Date();
      const fiveYearsFuture = new Date(
        now.getFullYear() + endYearsInFuture,
        now.getMonth(),
        1
      );
      const hundredYearsAgo = new Date(
        now.getFullYear() - startYearsAgo,
        now.getMonth(),
        1
      );

      const tl = new Timeline(
        ref.current,
        new DataSet([]),
        new DataSet(groups),
        {
          start: hundredYearsAgo,
          end: fiveYearsFuture,
          zoomMin: 86400000 * minZoomDays,
          zoomKey: "ctrlKey",
          horizontalScroll: true,
          width,
          height,
        }
      );
      setTimeline(tl);
      return () => {
        tl.destroy();
      };
    }
  }, [ref, startYearsAgo, endYearsInFuture, minZoomDays, width, height]);

  return <Box ref={ref} height={height} />;
}
